import { Controller } from "stimulus"

const mediaQuery = window.matchMedia('(max-width: 1024px)')

export default class extends Controller {
  static targets = [ 'showHideButton', 'content', 'arrowButton' ]

  showHide() {
    this.contentTarget.classList.toggle('visible');

    if (this.hasShowHideButtonTarget) {
      if (this.showHideButtonTarget.innerText === 'Show') {
        this.showHideButtonTarget.innerText = 'Hide';
      }
      else {
        this.showHideButtonTarget.innerText = 'Show';
      }
    }

    if (this.hasArrowButtonTarget) {
      this.arrowButtonTarget.classList.toggle('opened')
    }
  }

  goToSection(event) {
    // If we don't have a sticky header, use the default behavior
    if (mediaQuery.matches) {
      return;
    }

    let url = event.currentTarget.getAttribute('href')
    let section = document.querySelector(url)

    let headerHeight = 0;
    let header = document.getElementsByTagName('header')[0];

    // Since we have a sticky header, the idea is to substract the header's height
    // from the section's offset
    if (header.classList.contains('sticky-header') && !mediaQuery.matches) {
      headerHeight = header.scrollHeight;
    }

    let offset = section.getBoundingClientRect().top + window.pageYOffset - headerHeight

    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    })

    event.preventDefault()
  }
}