import { Controller } from "stimulus"

const mediaQuery = window.matchMedia('(max-width: 1024px)')

export default class extends Controller {
  static targets = [ 'stickBlock' ]

  connect() {
    this.initialDistance = this.stickBlockTarget.offsetTop
    this.initialWidth = this.stickBlockTarget.scrollWidth
    this.parentTarget = this.stickBlockTarget.offsetParent
    this.parentTargetDistance =  Math.floor(this.parentTarget.getBoundingClientRect().top) + window.pageYOffset
  }



  stickOn() {
    if (mediaQuery.matches) {
      return;
    }

    let element = this.stickBlockTarget

    let headerHeight = 0
    let header = document.getElementsByTagName('header')[0]
    let distanceToTop = this.parentTargetDistance + this.initialDistance

    if (header.classList.contains('sticky-header')) {
      headerHeight = header.scrollHeight + 4
    }

    let limitToBottom = this.parentTarget.getBoundingClientRect().bottom - headerHeight
    let scrollY = window.scrollY + headerHeight

    if ( scrollY >= distanceToTop ) {
      element.style.width = this.initialWidth + 'px'
      element.style.top = headerHeight + 'px'
      element.classList.add('sticky')
    } else {
      element.style.top = ''
      element.classList.remove('sticky')
    }

    if ( limitToBottom <= element.scrollHeight ) {
      element.classList.add('on-bottom')
    } else {
      element.classList.remove('on-bottom')
    }
  }

}


