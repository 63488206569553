import { Controller } from "stimulus"
import { loadStripe } from "@stripe/stripe-js"

export default class extends Controller {
  static targets = [ 'container', 'messageError', 'loader', 'messagePaymentProcessed', 'payButton', 'dataForm', 'email', 'inputParam' ]
  static values = { stripeKey: String, paymentIntentUrl: String, paymentIntentParameters: Object, amount: Number, email: String, url: String }

  async connect() {
    this.stripe = await loadStripe(this.stripeKeyValue);

    const appearance = {
      theme: 'stripe',

      variables: {
        fontFamily: '--body-font-family: "Open Sans", sans-serif',
        fontSizeSm: '16px',
        spacingUnit: '5px',
        borderRadius: '30px',
        fontWeightMedium: '500',
      },

      rules: {
        '.Label': {
          fontWeight: 'var(--fontWeightMedium)',
          marginTop: '14px'
        }
      }
    };

    const options = {
      mode: 'payment',
      amount: this.amountValue,
      currency: 'usd',
      appearance: appearance,
    };

    this.stripeElements = this.stripe.elements(options)
    const paymentElement = this.stripeElements.create('payment')
    paymentElement.mount(this.containerTarget);
  }

  async submitPayment() {
    if (this.hasDataFormTarget) {
      let isValid = this.validateInputs()

      if (!isValid) {
        return
      } else {
        this.dataFormTarget.submit() // TODO: Fix this submit
      }
    }

    // Make sure there are no validation errors on the credit card form
    const {error: submitError} = await this.stripeElements.submit();

    if (submitError) {
      this.showError(submitError.message)
      return
    }

    if (this.messageErrorTarget.classList.contains('visible')) {
      this.messageErrorTarget.classList.remove('visible')
    }

    this.messagePaymentProcessedTarget.classList.add('visible')
    this.loaderTarget.classList.add('visible')
    this.payButtonTarget.disabled = true

    const res = await fetch(this.paymentIntentUrlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      // Merge any paymentIntentParameters specified with any other "inputParam" values
      body: JSON.stringify({ ...this.paymentIntentParametersValue, ...this.inputParams })
    })

    const {client_secret: clientSecret} = await res.json();

    const url = this.urlValue
    const email = this.hasEmailValue ? this.emailValue : this.emailTarget.value

    let args = {
      elements: this.stripeElements,
      clientSecret: clientSecret,
      confirmParams: {
        return_url: url,
        payment_method_data: {
          billing_details: {
            email: email
          }
        }
      },
      redirect: 'if_required'
    }

    const response = await this.stripe.confirmPayment(args);

    if (response.error) {
      this.showError(response.error.message)
    }
    else {
      window.location.replace(url)
    }
  }

  showError(errorMessage) {
    this.messageErrorTarget.innerText = errorMessage
    this.messageErrorTarget.classList.add('visible')

    if (this.messagePaymentProcessedTarget.classList.contains('visible')) {
      this.messagePaymentProcessedTarget.classList.remove('visible')
    }

    if (this.loaderTarget.classList.contains('visible')) {
      this.loaderTarget.classList.remove('visible')
    }

    if (this.payButtonTarget.disabled === true) {
      this.payButtonTarget.disabled = false
    }
  }

  validateInputs() {
    let isValid = true

    this.inputs.reverse().forEach((input) => {
      if (!input.checkValidity()) {
        input.reportValidity()
        isValid = false
      }
    })

    return isValid
  }

  get inputs() {
    return Array.from(this.dataFormTarget.querySelectorAll('input'))
  }

  get inputParams() {
    const params = {}
    this.inputParamTargets.forEach((element) => {
      params[element.name] = element.value
    })
    return params
  }
}