import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['button', 'content', 'container']

  showContent(event) {
    let contentId = event.currentTarget.getAttribute('data-content');

    console.log(this.buttonTargets)
    // Removing 'selected' class
    this.buttonTargets.forEach((button) => {
      button.classList.remove('selected');
    })

    // Adding 'selected' class to pressed pill
    event.currentTarget.classList.add('selected');

    // Removing the 'visible' class in each section and adding it for the selected section
    this.contentTargets.forEach((block) => {
      block.classList.remove('visible');
      if (block.getAttribute('data-content') === contentId) {
        block.classList.add('visible')
      }
    })
  }
}