import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['hiddenBlock', 'blockToHide'];
  static values = { overlay: Boolean }

  showBlock() {
    this.hiddenBlockTarget.classList.add('visible')

    if (this.hasBlockToHideTarget) {
      this.blockToHideTarget.classList.add('visibility-hidden')
    }

    if (this.overlayValue) {
      this.addOverlay()
    }
  }

  hideBlock() {
    this.hiddenBlockTarget.classList.remove('visible')

    if (this.hasBlockToHideTarget) {
      this.blockToHideTarget.classList.remove('visibility-hidden')
    }

    if (this.overlayValue) {
      this.removeOverlay()
    }
  }

  addOverlay() {
    let overlay = document.createElement('div');
    overlay.className = 'overlay';
    document.body.appendChild(overlay);
  }

  removeOverlay() {
    let overlay = document.body.querySelector('.overlay')
    overlay.remove();
  }
}