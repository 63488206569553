import { Controller } from "stimulus"

const currentStep = "[data-active='true']"
const requiredField = "[required='required']"

export default class extends Controller {
  static targets = ['form', 'fieldGroup', 'step', 'stepIndicator', 'stepDescription', 'backButton', 'nextButton']

  sibling(dir) {
    const steps = this.steps
    const active = this.activeStep
    const index = steps.indexOf(active)

    if (dir === 'next') {
      return steps[index + 1]
    } else if (dir === 'back') {
      return steps[index - 1]
    }
  }

  active(target) {
    const previouslyActive = this.activeStep
    const stepIndex = this.steps.indexOf(target)

    if (previouslyActive) {
      previouslyActive.removeAttribute("data-active")
      previouslyActive.classList.remove('visible')
    }

    target.setAttribute("data-active", "true")
    target.classList.add('visible')

    this.stepsIndicators.forEach((item, index) => {
      if (index <= stepIndex) {
        item.classList.add('active')
      } else {
        item.classList.remove('active')
      }
    })

    this.stepsDescriptions.forEach((item, index) => {
      if (index === stepIndex) {
        item.classList.remove('d-none')
      } else {
        item.classList.add('d-none')
      }
    })
  }

  backStep() {
    const item = this.sibling('back')
    if (item) this.active(item)
  }

  nextStep() {
    const item = this.sibling('next')
    const emptyInputs = this.emptyInputsRequired

    if (item) {
      if (emptyInputs.length > 0) {
        emptyInputs.forEach(input => {
          input.closest('.generator-field__group').querySelector('.generator-field__label').classList.add('show-required')
        })
      } else {
        this.active(item)
      }
    }

  }

  removeRequiredLabel(event) {
    const parent = event.currentTarget.parentElement

    if (parent.classList.contains('input-group')) parent.classList.add('focused')

    event.currentTarget.closest('.generator-field__group').querySelector('.generator-field__label').classList.remove('show-required')
  }

  makeRequiredInputs(event) {
    const target = event.currentTarget
    const valueToMatch =  target.getAttribute('data-secondary-value')
    const inputs = this.activeStep.querySelectorAll(`[data-secondary-target="${valueToMatch}"]`)

    if (target.hasAttribute('data-secondary-active')) {
      inputs.forEach(input => (input.setAttribute("data-required", "true")) )
    } else {
      inputs.forEach(input => (input.removeAttribute("data-required")) )
    }
  }

  validateInput(event) {
    const parent = event.currentTarget.parentElement

    if (parent.classList.contains('focused')) parent.classList.remove('focused')

    if ( parent.classList.contains('input-group') && event.currentTarget.hasAttribute("required") ) {
      if (event.currentTarget.checkValidity()) {
        parent.classList.add('valid')
        parent.classList.remove('invalid')
      } else {
        parent.classList.remove('valid')
        parent.classList.add('invalid')
      }
    }
  }

  get steps() {
    return Array.from(this.stepTargets)
  }

  get stepsIndicators() {
    return Array.from(this.stepIndicatorTargets)
  }

  get stepsDescriptions() {
    return Array.from(this.stepDescriptionTargets)
  }

  get activeStep() {
    return this.formTarget.querySelector(currentStep)
  }

  get emptyInputsRequired() {
    const inputs = this.inputsRequired
    const optionsGroups = this.inputsOptions

    let emptyInputs = []
    let optionsGroupByNames = []

    inputs.forEach(input => {
      if (input.value === '' && !input.disabled) return emptyInputs.push(input)
    })

    optionsGroups.forEach(input => {
      if (optionsGroupByNames.includes(input.name)) return;
      optionsGroupByNames.push(input.name)

      const options = this.activeStep.querySelectorAll(`[name="${input.name}"]`)
      const isChecked = Array.from(options).some(function (input) {
        return input.checked
      })
      if (!isChecked) {
        return emptyInputs.push(input)
      }
    })

    return emptyInputs
  }

  get inputsRequired() {
    return this.activeStep.querySelectorAll(requiredField)
  }

  get inputsOptions() {
    return this.activeStep.querySelectorAll('[type="radio"], [type="checkbox"][data-required="true"]')
  }
}