import { Controller } from "stimulus"

const mediaQuery = window.matchMedia('(max-width: 1024px)')

// Controller in charge of scrolling to a given element.
export default class extends Controller {
  static targets = [ ]

  initialize() {
    this.overlayTarget = this.data.get('overlayTarget')
    this.overlayFocus = this.data.get('overlayFocus')
    this.fn = this.onOverlayClicked.bind(this)
  }

  // If data-target="scroll-to-target-offset" is specified, we use that value.
  // If not, we'll grab the element ID from the event.curentTarget's href
  //
  scroll(event) {
    let offset = this.data.get('offset')

    if (offset == null) {
      let url = event.currentTarget.getAttribute('href')
      let section = document.querySelector(url)

      let headerHeight = 0;
      let header = document.getElementsByTagName('header')[0];

      // Since we have a sticky header, the idea is to substract the header's height
      // from the section's offset
      if (header.classList.contains('sticky-header') && !mediaQuery.matches) {
        headerHeight = header.scrollHeight;
      }

      let extraOffset = parseInt(this.data.get('extraOffset')) || 0

      offset = section.getBoundingClientRect().top + window.pageYOffset - headerHeight + extraOffset;

    }

    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    })

    let target = this.targetElement()
    let focus = this.focusElement()

    if (target != null) {
      this.toggleOverlay(target, focus);

      target.addEventListener('click', this.fn);
    }

    event.preventDefault()
  }

  onOverlayClicked() {
    let target = this.targetElement()
    let focus = this.focusElement()

    this.toggleOverlay(target, focus)

    target.removeEventListener('click', this.fn)
  }

  targetElement() {
    if (this.overlayTarget == null) {
      return null;
    }

    return document.querySelector(this.overlayTarget);
  }

  focusElement() {
    if (this.overlayFocus == null) {
      return null;
    }

    return document.querySelector(this.overlayFocus);
  }

  toggleOverlay(targetElement, focusElement) {
    targetElement.classList.toggle('d-none')
    if (focusElement != null) {
      focusElement.classList.toggle('z-index-1000')
      focusElement.closest('.container').classList.toggle('z-index-50')
    }
  }
}